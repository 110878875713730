import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { getCookie } from 'typescript-cookie';
import { VersionService } from './services/version.service';
import { ConfettiService } from './services/confetti/confetti.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  title = 'classroom-studio';
  appVersion: string = '';

  constructor(
    private router: Router,
    private versionService: VersionService,
    private confettiService: ConfettiService
  ) {}

  @HostListener('document:click', ['$event'])
  // onDocumentClick(event: MouseEvent): void {
  //   const x = event.clientX / window.innerWidth;
  //   const y = event.clientY / window.innerHeight;
  //   this.confettiService.launchConfetti(x, y);
  // }
  ngOnInit() {
    this.appVersion = this.versionService.getVersion().version;
    // this.router.events
    //   .pipe(filter((event) => event instanceof NavigationEnd))
    //   .subscribe((event) => {
    //     const userLocation = localStorage.getItem('user_location');
    //     const access_token = getCookie('access_token');
    //     const tacStatus = localStorage.getItem('tac_status');

    //     console.log('tacStatus', tacStatus, typeof tacStatus);
    //     console.log('access_token', access_token);
    //     if (access_token) {
    //       if (tacStatus === 'false') {
    //         console.log('ok tac');
    //         this.router.navigateByUrl('/auth/terms-and-conditions');
    //         return;
    //       }
    //       if (!userLocation) {
    //         console.log('ok createprofile');
    //         this.router.navigateByUrl('/auth/create-profile');
    //         return;
    //       }
    //     }
    //     // if (!access_token && ) {
    //     //   this.router.navigateByUrl('/auth/login');
    //     //   return;
    //     // }
    //   });
  }
}
