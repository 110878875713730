import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { getCookie } from 'typescript-cookie';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { LoaderService } from '../loader.service';

import { Observable, finalize } from 'rxjs';

/** Inject With Credentials into the request */
@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  urlsToNotUse: string[] = [
    'auth/registration/',
    'auth/password/reset/confirm/',
    'auth/resend-confirmation/',
    'auth/password/reset/',
    'terms/retrieve/',
  ];
  urlsNoLoader: string[] = [
    '/utilities/subjects/',
    '/utilities/calendar/',
    '/api/planner/',
  ];
  constructor(private router: Router, private loaderService: LoaderService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let noGo = false;
    for (const address of this.urlsNoLoader) {
      if (request.url.includes(address)) {
        noGo = true;
      }
    }

    if (!noGo) {
      this.loaderService.toggle(true);
    }

    if (this.isValidRequestForInterceptor(request.url)) {
      let token = getCookie('access_token');
      if (token) {
        request = request.clone({
          setHeaders: {
            Authorization: `Token ${token}`,
          },
        });
      } else {
        this.router.navigateByUrl('/auth/login');
      }
    }

    return next
      .handle(request)
      .pipe(finalize(() => this.loaderService.toggle(false)));
  }

  private isValidRequestForInterceptor(requestUrl: string): boolean {
    let positionIndicator: string = 'api/';
    let position = requestUrl.indexOf(positionIndicator);
    if (position > 0) {
      let destination: string = requestUrl.substr(
        position + positionIndicator.length
      );
      for (let address of this.urlsToNotUse) {
        if (new RegExp(address).test(destination)) {
          return false;
        }
      }
    }
    return true;
  }
}
